<script setup lang="ts">
</script>

<!--
 template内にhtml,head,bodyは含まない
 -->
<template>
  <div>
    <div class="container my-container">
      <div class="d-flex justify-content-center mt-4">
        <a href="/"><img src="~/assets/images/stack3-icon.svg" class="stack3-icon" alt="Stack3"></a>
      </div>
      <div class="mt-4 mb-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/css/variables.scss";

main a {
  color: $color-link;
}
</style>

<style lang="scss" scoped>
.stack3-icon {
  width: 64px;
  height: 64px;
}
.header-logo {
  display: block;
  width: 200px;
}

.my-container {
  margin: 0 auto;
  width: 100%;
  max-width: 576px;
}
</style>